import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { roleMapping, User } from '../types/user.types';
import { RemoveUser, UpdateUser } from 'src/app/state/actions/settings.actions';
import { Observable, catchError, lastValueFrom } from 'rxjs';
import { PUBLICLOGIN } from 'src/app/authentication-lead/authentication-lead.module';
import { OriginalUrlService } from './original-url.service';
import { UtilsService } from './utils.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private originalUrlService: OriginalUrlService,
    private router: Router,
    private store: Store<AppState>,
    private utilsService: UtilsService
  ) {}

  loginUrl = environment.apiUrl + '/auth/jwt/create/';

  public signUp(newUser) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/', { ...newUser });
  }

  public validateUser(uid, token) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/users/request_user_activation/', { uid, token });
  }

  /**
   * Lets the user log with standard credentials to BE
   * @param username login credential to project's BE
   * @param password login credential to project's BE
   * @returns True if user logged in successfuly or False otherwise
   */
  public async logIn(username: string, password: string): Promise<User | null> {
    try {
      const logInResult = await lastValueFrom(
        this.http.post<{ access: string; refresh: string }>(this.loginUrl, {
          username,
          password,
        })
      );
      if (logInResult && logInResult.access) {
        const now = new Date();
        const tokenExpiration = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        localStorage.setItem('tokenExpiration', tokenExpiration.toString());
        localStorage.setItem('access_token', logInResult.access);
        localStorage.setItem('refresh_token', logInResult.refresh);
        const user = await this.getMe();
        this.store.dispatch(new UpdateUser(user));
        return user;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  isTokenExpired(): boolean {
    const expiration = localStorage.getItem('tokenExpiration');
    if (!expiration) {
      return true;
    }
    const expirationDate = new Date(expiration);
    return expirationDate <= new Date();
  }

  public async refreshToken() {
    if (this.isTokenExpired()) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        return null;
      }
      try {
        this.http.post<any>(`${environment.apiUrl}/auth/jwt/refresh/`, { refresh: refreshToken }).subscribe({
          next: results => {
            if (results && results.access) {
              const now = new Date();
              const tokenExpiration = new Date(now.getTime() + 24 * 60 * 60 * 1000);
              localStorage.setItem('tokenExpiration', tokenExpiration.toString());
              localStorage.setItem('access_token', results.access);
              return results.access;
            }
          },
          error: (err: any) => {
            console.error('Invalid response from token refresh: ', err.error);
            this.signOut();
            this.router.navigate([PUBLICLOGIN]);
          },
          complete: () => {},
        });
      } catch (error) {
        // Handle error from token refresh request
        console.error('Error refreshing token: ', error);
        return null;
      }
    }
    return localStorage.getItem('access_token');
  }

  /**
   * Removes user's traces
   */
  signOut(): void {
    this.originalUrlService.setOriginalUrl(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('user_role');
    localStorage.removeItem('unreadConv');
    this.store.dispatch(new RemoveUser());
    this.utilsService.clearStore();

    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    setTimeout(() => this.router.navigate([PUBLICLOGIN]));
  }

  /**
   * Fetches the currently authenticated user from the backend using the access token in local storage.
   *
   * If the token is invalid or not present, the user is logged out, and null is returned. The function
   * also resolves the user's role based on specific logic, including handling `dlRegister` overrides.
   *
   * @param dlRegister - If request is from deep_link registration, the user's `last_used_role` is directly used for the `role` field.
   * @returns A `User` object if the token is valid, or `null` if the token is invalid or not present.
   */
  public async getMe(dlRegister = false): Promise<User> {
    if (localStorage.getItem('access_token') === null) {
      return null;
    }
    try {
      const user = await lastValueFrom(this.getUser());

      // Resolve the user's role based on logic and mappings
      let role: string =
        // First, it attempts to fetch the user's role from `localStorage`
        localStorage.getItem('user_role') ||
        // Then if the user's last used role is not 'AD', that role is used. Otherwise, it maps the user's roles (excluding role with value `1`== AD) to a corresponding code from the `roleMapping` object.
        (user.last_used_role !== 'AD' ? user.last_used_role : roleMapping[user.roles.find(role => role !== 1)]?.code);
      // Override role if `dlRegister` is true
      if (dlRegister) {
        role = user.last_used_role;
      }
      // Construct the internal user data object
      const user_data: User = {
        username: user.username,
        id: user.id,
        email: user.email,
        roles: user.roles,
        role: role,
        first_name: user.first_name,
        last_name: user.last_name,
      };
      return { ...user_data };
    } catch (err) {
      this.signOut();
      this.router.navigate([PUBLICLOGIN]);
      return null;
    }
  }

  public getUser(): Observable<User> {
    return this.http.get<User>(environment.apiUrl + '/auth/users/me/').pipe(
      catchError(error => {
        throw error; // Rethrow the error so it can be caught downstream
      })
    );
  }

  public updateUser(updatedData): Observable<User> {
    return this.http.patch<User>(environment.apiUrl + '/auth/users/me/', { ...updatedData });
  }

  public resetPassword(email: string) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/reset_password/', { email });
  }

  public resetPasswordConfirm(uid: string, token: string, new_password: string) {
    return this.http.post<HttpResponse<any>>(
      environment.apiUrl + '/auth/users/reset_password_confirm/',
      { uid, token, new_password },
      { observe: 'response' }
    );
  }

  public resetPasswordTokenCheck(uid, token) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/reset_token_check', {
      uid,
      token,
    });
  }

  public setPassword(current_password, new_password) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/set_password/', {
      current_password,
      new_password,
    });
  }
}

import { CommonModule } from '@angular/common';
import {} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PrimeNGModule } from './primeng.module';
import { PictureCropperComponent } from './components/picture-cropper/picture-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommentComponent } from './components/comment/comment.component';
import { ConversationsComponent } from './components/conversations/conversations.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { GoalsGeneratorComponent } from '../smart-goals-generator/goals-generator/goals-generator.component';
import { GoalsCardComponent } from '../relation/components/goals-card/goals-card.component';
import { SafeHTMLPipe } from '../shared/pipes/safe-html.pipe';
import { GoalsTrackerComponent } from '../relation/components/goals-tracker/goals-tracker.component';
import { MatchedPopupComponent } from './components/matched-popup/matched-popup.component';

const SHARED_MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, PrimeNGModule];

@NgModule({
  declarations: [
    CommentComponent,
    ConversationsComponent,
    PictureCropperComponent,
    FileUploadComponent,
    GoalsGeneratorComponent,
    GoalsCardComponent,
    GoalsTrackerComponent,
    SafeHTMLPipe,
    MatchedPopupComponent,
  ],
  imports: [...SHARED_MODULES, ImageCropperModule],
  exports: [
    ...SHARED_MODULES,
    CommentComponent,
    ConversationsComponent,
    PictureCropperComponent,
    FileUploadComponent,
    GoalsGeneratorComponent,
    GoalsCardComponent,
    GoalsTrackerComponent,
    SafeHTMLPipe,
    MatchedPopupComponent,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}

import { Component, Input, Output } from '@angular/core';
@Component({
  selector: 'fp-matched-popup',
  templateUrl: './matched-popup.component.html',
})
export class MatchedPopupComponent {
  @Input() showPopup: boolean;

  closeDialog() {
    this.showPopup = false;
  }
}

<p-dialog
  [(visible)]="showPopup"
  breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  [modal]="true"
  [closable]="true"
  [style]="{ width: '470px', textAlign: 'center' }"
>
  <h3 class="mt-0 px-6">We contacted you regarding a new potential mentee!</h3>
  <p class="text-14">
    We've emailed you with a request to mentor another mentee. Please take a moment to review it and let us know your thoughts at your
    earliest convenience!
  </p>
  <p-button pRipple label="Close" styleClass="p-button-warning mt-3 text-14 w-4" (click)="closeDialog()"></p-button>
</p-dialog>

<p-progressBar *ngIf="notificationCenter.loading" mode="indeterminate" [style]="{ height: '3px', position: 'fixed' }"></p-progressBar>
<header class="flex align-items-center pl-3 pr-3">
  <div class="flex flex-wrap justify-content-between align-items-center w-full">
    <div style="cursor: pointer" class="logo col-auto align-items-center my-2 xl:ml-5 mr-1">
      <ng-container *ngIf="user?.role === 'CC'">
        <img tabindex="-1" [routerLink]="COMPANY" class="logo flex" src="assets/FP-logo.png" alt="FP logo" />
      </ng-container>
      <ng-container *ngIf="user?.role === 'LD'">
        <img tabindex="-1" [routerLink]="CAREERPLANNER" class="logo flex" src="assets/FP-logo.png" alt="FP logo" />
      </ng-container>
      <ng-container *ngIf="user?.role === 'CH'">
        <img tabindex="-1" (click)="navigateToRelation()" class="logo flex" src="assets/FP-logo.png" alt="FP logo" />
      </ng-container>
      <ng-container *ngIf="user?.role === 'ME' || user?.role === 'MR'">
        <img tabindex="-1" [routerLink]="DASHBOARD" class="logo flex" src="assets/FP-logo.png" alt="FP logo" />
      </ng-container>
    </div>

    <div class="align-items-center xl:flex mr-5 xl:mr-0">
      <ng-container *ngIf="user?.role === 'CC'">
        <img tabindex="-1" [routerLink]="COMPANY" class="logo mr-6 xl:mr-4" [src]="logo" alt="Client logo" />
      </ng-container>

      <ng-container *ngIf="!headerLoading && user?.role !== 'LD'">
        <ng-container *ngIf="getUnreadConvFromLocalStorage().length > 0">
          <i
            #bellIcon
            class="pi pi-bell mr-3 text-xl p-text-secondary cursor-pointer"
            severity="warning"
            (click)="toggleConversations()"
            pBadge
            [value]="getUnreadConvFromLocalStorage().length.toString()"
            data-umami-event="Toggle Conversations"
          ></i>
        </ng-container>
        <ng-container *ngIf="getUnreadConvFromLocalStorage().length === 0">
          <i #bellIcon class="pi pi-bell mr-3 text-xl p-text-secondary cursor-pointer" (click)="toggleConversations()"></i>
        </ng-container>
      </ng-container>
      <div *ngIf="!headerLoading" class="align-items-center hidden xl:flex">
        <p-avatar
          *ngIf="photo"
          #avatar
          [image]="photo"
          styleClass="mr-2"
          shape="circle"
          onselectstart="false"
          class="noselect"
          styleClass="user-avatar cursor-auto"
          severity="danger"
          size="large"
          (click)="menu.toggle($event)"
          pRipple
        ></p-avatar>
        <p-avatar
          *ngIf="!photo"
          #avatar
          [label]="label"
          styleClass="mr-2"
          shape="circle"
          onselectstart="false"
          class="noselect"
          styleClass="user-avatar cursor-auto"
          severity="danger"
          size="large"
          (click)="menu.toggle($event)"
          pRipple
        ></p-avatar>
        <p-menu #menu [popup]="true" [model]="menuItems" data-test="avatar-menu"></p-menu>
      </div>
    </div>
  </div>
</header>
<!-- overlayer for Notification center -->
<div #conversations [class]="displayConversations ? 'block' : 'hidden'">
  <fp-conversations [user]="user"></fp-conversations>
</div>

<!-- overlayers for system notifications -->
<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>
<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-scrollTop></p-scrollTop>

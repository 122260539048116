import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import { MenteeRelationService } from 'src/app/shared/services/mentee-relation.service';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import * as MRActions from '../actions/mentee-relations.actions';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class MenteeRelationsEffects {
  public menteeRelationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MRActions.MenteeRelationLoad>(MRActions.MENTEERELATION_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.menteerelationService.getMenteeRelations(payload.ids, payload.role_type).pipe(
          map(data => {
            const menteeRelations: MenteeRelation[] = data;
            return new MRActions.MenteeRelationLoadSuccess(menteeRelations);
          }),
          catchError(() => of(new MRActions.MenteeRelationLoadError()))
        )
      )
    )
  );

  public menteerelationLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MRActions.MenteeRelationLoadSuccess>(MRActions.MENTEERELATION_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public menteerelationLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MRActions.MenteeRelationLoadError>(MRActions.MENTEERELATION_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Relation load failed'))
      ),
    { dispatch: false }
  );

  public updateMenteerelation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MRActions.UpdateMenteeRelation>(MRActions.UPDATE_MENTEERELATION),
      switchMap(payload =>
        this.menteerelationService.updateMenteeRelation(payload.relation).pipe(
          map(data => {
            const menteeRelation: MenteeRelation = data;
            return new MRActions.UpdateMenteeRelationSuccess(menteeRelation);
          }),
          catchError(() => of(new MRActions.UpdateMenteeRelationError()))
        )
      )
    )
  );

  public updateMenteerelationSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MRActions.UpdateMenteeRelationSuccess>(MRActions.UPDATE_MENTEERELATION_SUCCESS),
        withLatestFrom(this.store$.select(state => state.menteeRelations))
      ),
    { dispatch: false }
  );

  public updateMenteerelationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MRActions.UpdateMenteeRelationError>(MRActions.UPDATE_MENTEERELATION_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Relation update failed'))
      ),
    { dispatch: false }
  );

  autosave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...MRActions.STATE_MODIFYING_ACTIONS),
      debounceTime(500),
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev.relation) === JSON.stringify(curr.relation);
      }),
      map(data => {
        if (data) {
          return new MRActions.UpdateMenteeRelation(data.relation as Partial<MenteeRelation>);
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private menteerelationService: MenteeRelationService,
    private store$: Store<AppState>
  ) {}
}
